import React from 'react'
import Table from 'react-bootstrap/Table';

import Tableapi from './Tableapi';

export default function dashboard() {
  return (
    <div>
    <div className="my-3">Table Member</div>
    
    

    <Table striped bordered hover className="my-3 px-3" >
    <thead>
      <tr>
        <th>#</th>
        <th>Nama</th>
        <th>Tanggal</th>
        <th>Aksi</th>
      </tr>
    </thead>
   <Tableapi/>
  </Table>
  </div>
  )
}
