import React from "react";
import Dashboard from "./component/Dashboard";


function App() {
  return (
    <div className="row vh-100">
     
      <section className="col-12 bg-white py-5 px-3">
        <Dashboard />
      </section>
    </div>
  );
}

export default App;