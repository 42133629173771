import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { useState} from 'react'

export default function Modalku(props) {
  
// button start
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow =  () => setShow(true);
; 


  return (
    <>
      <Button variant="primary" onClick={handleShow}>
               Detail
              </Button>
              <Modal  size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Data Pelanggan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* No : {props.no} <br></br>  */}
            <Card body className="my-2 px-2 fw-bold text-capitalize">

           
            Nama Penerima: {props.nama} <br></br>
            No Telepon / WhatsApp : {props.wa} <br></br>
            Kota / Kabupaten : {props.kota} <br></br>
            Kecamatan / Kelurahan : {props.kecamatan} <br></br>
            Rt / Rw : {props.rt} <br></br> 
            Alamat / Patokan : {props.alamat} <br></br>
            Keterangan lainnya : {props.ket} <br></br>
            <br></br>
            
            <br></br>
            </Card>
            <Card body className="mb-4 fs-6 fw-semibold text-capitalize">Jenis Pembayaran: {props.pay}</Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  )
}
