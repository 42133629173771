import React from 'react'
import axios from 'axios'
import { useState, useEffect} from 'react'
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import Modalku from './Modalku';
// import { Chekbox } from './Chekbox';
import Spinner from 'react-bootstrap/Spinner';



export default function Tableapi() {
//    akses api
  const [read, setReads] = useState("")
  const getreads = async () => {
    try {
      let response = await axios.get('https://script.google.com/macros/s/AKfycbwtpPxXMlBBKLI2a_gdOKXHWb4wOlB9De97oRmpCvMoyZ7LhG8HUZftiLOllOBx87w/exec?action=read')
 
      setReads(response.data.records)
            // console.log(response.data.records)
    } catch (e) {
      console.log(e.message);
    }
  }

  

  useEffect(() => {
    getreads();
  }, []);

  //end api

    

// button end




// ListItem memasuka item
  function ListItem() {
    if (!read) {
        return (<tr>
            <td colSpan={4}>

            <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
            </td>
             </tr>
             )
      }
    // Correct! There is no need to specify the key here:
    return (
        read.map((data, i) => {

        return (
    
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{data.Nama_Penerima}</td>
          <td>{data.Timestamp}</td>
       
          <td>
             <Modalku no={i + 1}
              nama={data.Nama_Penerima} 
              kota={data.Kota_Atau_Kabupaten} 
              pay={data.Jenis_Pembayaran}
              kecamatan={data.Kecamatan_Atau_Kelurahan}
              ket={data.Keterangan_Lainnya}
              alamat={data.No_rumah_Atau_Patokan_Atau_Alamat_lengkap}
              wa={data.Nomor_telepon_Atau_Whatsapp}
              rt={data.Rt_dan_Rw}
/>


             
          </td>
        
        </tr>
        )
    })

        )
  }
// end list imem




// mengeluarkan data
  return (
    <tbody className=''>
    <ListItem />


    </tbody>
  )
}
